*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar
{
  width: 15px;
}

::-webkit-scrollbar-track
{
  background-color: #7F8487;
}

::-webkit-scrollbar-thumb
{
  background-color: rgb(205,205,205);
  height: 1%;
  border-radius: 10px;
}