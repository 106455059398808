body{
background-image: repeating-linear-gradient(45deg, rgb(205,205,205) 0px, rgb(205,205,205) 2px,transparent 2px, transparent 4px),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255),rgb(255,255,255));
}

.border
{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.mar
{
    font-family: monospace;
}